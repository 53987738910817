export const environment = {
    production: true,
    environment: 'acceptance',
    apiUrl: 'https://acc-archief.boomonderwijs.nl',
    mercureHub: 'https://acc-archief.boomonderwijs.nl/.well-known/mercure',
    fileDownloadUrl: 'https://acc-archief.boomonderwijs.nl/api/nl/file/download/',
    logoutUrl: '/security/keycloak/logout',
    teachingMaterialsUrl: 'https://aanvragen.edu-actief.nl',
    teachingMaterialsUrlBoomvo: 'https://mijn.boomvo.nl',
    maintenanceUrl: '/maintenance.html',
    sentryDsn: 'https://d775bf6873cfaae064eb40b47c66ed2c@o4507333400461312.ingest.de.sentry.io/4507389713055824',
    termsAndConditionsUrl: 'https://webshop.edu-actief.nl/klantenservice/algemene_voorwaarden',
    sentryEnabled: true,
    microsoftAppId: '6b477ca7-6681-4786-aa35-2436966eae37',
    microsoftRedirectUrl: 'https://acc-archief.boomonderwijs.nl',
    bboUrl: 'https://boomberoepsonderwijs.nl/contact/contactformulier',
    bvoUrl: 'https://boomvoortgezetonderwijs.nl/contact/',
    boomvoUrl: 'https://acc.boomdigitaal.nl',
    features: {
        teacherMaterialsEnabled: true,
        helpPageEnabled: true,
        onedriveEnabled: true,
        searchEnabled: true
    },
    archiveModeActive: true,
};
